import { useTranslation } from 'react-i18next' ;

import Header from '../Requires/Header' ;
import Footer from '../Requires/Footer' ;

function AboutUs() {
    const { t, i18n } = useTranslation() ;

    return (
        <main>
            <Header />

            {t('Welcome to React')}
            
            <section>
                <div class="spacing ylsh-layer opc7 top-spac70 bottom-spac70">
                    <div class="fixed-bg" style={{ backgroundImage: `url('images/about-us-2.png')` }} ></div>
                    <div class="container">
                        <div class="pur-sec-inr text-center">
                            <div class="bnc-cpt">
                                <img class="boxlogo" src="/images/icon-byte.png" alt="icon" itemprop="image" />
                                <h3 itemprop="headline">Byte Mission</h3>

                                <p class="pr mb20" itemprop="description" class="mb20">Optimise the development of the blockchain strategy through different market position and their use cases, focus on long term strategies to maximise shareholder value.</p>
                                <p class="pr"itemprop="description">Byte Holding, is one the largest eco-friendly crypto mining.  Byte Holdings own its green art powered mining facilities in Malaysia, which create alternative energy solution to maintain and boost profitability. Our services provide all exposure on the whole mining process and margins of crypto mining, with that we are able to generate a real-time crypto portfolio for shareholders. Leading institutional and retail investors to create a new era of the financial world.</p>
                             </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </main>
    );
}


export default AboutUs;
