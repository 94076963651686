import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Header from '../Requires/Header' ;
import Footer from '../Requires/Footer' ;

function CloudHosting() {
    return (
        <main>
            <Header />

            <section>
                <div class="spacing black-layer opc7 bottom-spac190">
                    <div class="fixed-bg" style={{ backgroundImage: `url('/images/bg-mining.png')` }} ></div>
                    <div id="prtcl2" class="particle-bx"></div>
                    <div class="container">
                        <div class="prchs-wrp">
                            <div class="row">
                                <div class="col-md-4 col-sm-12 col-lg-4">
                                    <div class="pbimg">
                                        <img src="/images/machine.png" alt="machine" itemprop="image" />
                                    </div>
                                </div>
                                <div class="col-md-8 col-sm-12 col-lg-8">
                                    <div class="pur-sec">
                                        <div class="pur-sec-inr">
                                            <h2 itemprop="headline">S19Jcloud hosting</h2>
                                            <span class="htag">Only Available On BYTE</span>
                                            <p itemprop="description mb20">Users enjoy the price advantage of bulk purchase of mining machines and low-cost hosting services.</p>
                                            <p itemprop="description mb20">Bit Fortune is responsible for all links (mining machine purchase, transportation, operation and maintenance, computing power management, electricity bill payment, etc.)</p>
                                            <p itemprop="description">After the user returns the book, the platform will charge the service fee again, and the service will not be charged before the book is returned</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="spacing no-spacing">
                    <div class="container">
                        <div class="fn-fct-wrp text-center bgwhite overlap125">
                            <div class="remove-ext3">
                                <div class="row">
                                    <div class="col-md-4 col-sm-4 col-lg-4">
                                        <div class="fn-fct-bx">
                                            <i class="iconp"><img src="/images/iconr1.png" alt="icon" itemprop="image" /></i>
                                            <h4 itemprop="headline">Safe & Risk-free</h4>
                                            <i>The output will be sent to the user’s personal address every day. The procedure is safe and transparent.</i>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-lg-4">
                                        <div class="fn-fct-bx">
                                            <i class="iconp"><img src="/images/iconr1.png" alt="icon" itemprop="image" /></i>
                                            <h4 itemprop="headline">Share after Cost Recovery</h4>
                                            <i>No hosting fee will be charged until you recover the cost. We share the risk with you.</i>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-lg-4">
                                        <div class="fn-fct-bx">
                                            <i class="iconp"><img src="/images/iconr1.png" alt="icon" itemprop="image" /></i>
                                            <h4 itemprop="headline">User Rights Guarantee</h4>
                                            <i>Overseas first-class compliance mine. The miner provides a six-month warranty</i>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="bottom-spac190">
                  <div class="container">
                     <div class="productboxs">
                       <div class="row">
                          <div class="col-md-12 col-sm-12 col-lg-12">
                             <div class="fea-bx mb50">
                                <div class="fea-bx-inf">
                                   <div class="row">
                                      <div class="col-md-3 col-lg-3 col-sm-12">
                                         <img class="ppic2" src="/images/product/1.png" alt="" itemprop="image" />
                                      </div>
                                      <div class="col-md-9 col-lg-9 col-sm-12">
                                         <h4 class="sub1">S19pro - 0815</h4>
                                         <h1 class="ptitle" itemprop="headline">S19Pro</h1>
                                         <h4 class="sub2">Luogo: U.S.A</h4>
                                         <div class="ptables">
                                             <table class="productdetails">
                                                <tr>
                                                   <td>Luogo:</td>
                                                   <td>U.S.A</td>
                                                </tr>
                                                <tr>
                                                   <td>Computing power：</td>
                                                   <td>110TH/s</td>
                                                </tr>
                                                <tr>
                                                   <td>Power</td>
                                                   <td>3245W</td>
                                                </tr>
                                                <tr>
                                                   <td>Energy ratio：</td>
                                                   <td>29.5J/T</td>
                                                </tr>
                                             </table>
                                             <table class="productdetails">
                                                <tr>
                                                   <td>Boot Time</td>
                                                   <td>2021-09-01</td>
                                                </tr>
                                                <tr>
                                                   <td>ELlectricity Fee</td>
                                                   <td>$0.0462T/Day</td>
                                                </tr>
                                                <tr>
                                                   <td>Hosting Fee:</td>
                                                   <td>30% of net output is collected after recovery</td>
                                                </tr>
                                                <tr>
                                                   <td>Repairment Fee</td>
                                                   <td class="bold">A six-month warranty</td>
                                                </tr>
                                             </table>
                                         </div>
                                         <a href="cloud-hosting-product-detail.html" class="theme-btn hrz brd-rd3 theme-bf-bg" title="" itemprop="url">Check Details</a>
                                      </div>
                                   </div>
                                </div>
                             </div>
                          </div>
                          <div class="col-md-12 col-sm-12 col-lg-12">
                             <div class="fea-bx mb50">
                                <div class="fea-bx-inf">
                                   <div class="row">
                                      <div class="col-md-3 col-lg-3 col-sm-12">
                                         <img class="ppic2" src="/images/product/1.png" alt="" itemprop="image" />
                                      </div>
                                      <div class="col-md-9 col-lg-9 col-sm-12">
                                         <h4 class="sub1">S19pro - 0815</h4>
                                         <h1 class="ptitle" itemprop="headline">S19Pro</h1>
                                         <h4 class="sub2">Luogo: U.S.A</h4>
                                         <div class="ptables">
                                             <table class="productdetails">
                                                <tr>
                                                   <td>Luogo:</td>
                                                   <td>U.S.A</td>
                                                </tr>
                                                <tr>
                                                   <td>Computing power：</td>
                                                   <td>110TH/s</td>
                                                </tr>
                                                <tr>
                                                   <td>Power</td>
                                                   <td>3245W</td>
                                                </tr>
                                                <tr>
                                                   <td>Energy ratio：</td>
                                                   <td>29.5J/T</td>
                                                </tr>
                                             </table>
                                             <table class="productdetails">
                                                <tr>
                                                   <td>Boot Time</td>
                                                   <td>2021-09-01</td>
                                                </tr>
                                                <tr>
                                                   <td>ELlectricity Fee</td>
                                                   <td>$0.0462T/Day</td>
                                                </tr>
                                                <tr>
                                                   <td>Hosting Fee:</td>
                                                   <td>30% of net output is collected after recovery</td>
                                                </tr>
                                                <tr>
                                                   <td>Repairment Fee</td>
                                                   <td class="bold">A six-month warranty</td>
                                                </tr>
                                             </table>
                                        </div>
                                         <a href="cloud-hosting-product-detail.html" class="theme-btn hrz brd-rd3 theme-bf-bg" title="" itemprop="url">Check Details</a>
                                      </div>
                                   </div>
                                </div>
                             </div>
                          </div>

                       </div>
                     </div>
                  </div>
                </div>
            </section>

            <Footer />

        </main>
    );
}


export default CloudHosting;
