import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Header from '../Requires/Header' ;
import Footer from '../Requires/Footer' ;

function MiningLogic() {
    return (
        <main>
            <Header />

            <section>
                <div class="spacing">
                    <div class="container ">
                        <div class="sec-tl2 text-center">
                            <i>Mining Logic</i>
                            <h2 class="no-bf" itemprop="headline">What Byte Holdings control, What Doesn’t</h2>
                        </div>
                        <div class="remove-ext3">
                            <div class="row equal-cols">
                                <div class="col-md-4 col-sm-12 col-lg-4">
                                    <div class="fea-bx">
                                        <i class="flaticon-009-share brd-rd50"></i>
                                        <div class="fea-bx-inf">
                                            <h4 itemprop="headline">Byte Holding Mining</h4>
                                            <p class="td" itemprop="description">
                                                Accelerate the renewable energy in the bitcoin ecosystem.<br />
                                                Mining, a process for bitcoin enters into market. <br />
                                                It’s verifies and secure the transaction information
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-12 col-lg-4">
                                    <div class="fea-bx">
                                        <i class="flaticon-019-settings-2 brd-rd50"></i>
                                        <div class="fea-bx-inf">
                                            <h4 itemprop="headline">Byte Holding control</h4>
                                            <p itemprop="description">
                                                <ul class="licheck">
                                                    <li>Our hash rate</li>
                                                    <li>Custodian fee</li>
                                                    <li>Facility Expenses</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-12 col-lg-4">
                                    <div class="fea-bx">
                                        <i class="flaticon-028-shield brd-rd50"></i>
                                        <div class="fea-bx-inf">
                                            <h4 itemprop="headline">Byte Holding do not control</h4>
                                            <p itemprop="description">
                                                <ul class="licheck">
                                                    <li>Bitcoin price</li>
                                                    <li>Miner’s price</li>
                                                    <li>Ownership of miners</li>
                                                    <li>Block reward & Blocks per year</li>
                                                    <li>Network difficulty</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </main>
    );
}


export default MiningLogic;
