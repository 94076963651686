import React, { useState, useEffect } from 'react' ;

import i18n from "i18next";
import { useTranslation } from "react-i18next" ;

import toast, { Toaster } from 'react-hot-toast' ;

import { saveKey, getKey } from '../Main/Main' ;

export default function Header() {
    const [ language, setLanguage ] = useState( 'en' ) ;
    const [ isLogin, setIsLogin ]   = useState( false ) ;
    const { t } = useTranslation() ;

    useEffect( async () => {
        const auth = await getKey( 'Auth' ) ;
        if ( auth != null ){
            setIsLogin( true ) ;
        }
    }) ;

    useEffect( () => {
        async function callLanguage() {
            const lang   = await getKey( 'i18nextLng', 'en', false ) ;
            setLanguage( lang ) ;
        }
        callLanguage() ;
    }, '' ) ;

    const changeLanguage = ( lang ) => {
        i18n.changeLanguage( lang ) ;
        window.location.reload(false) ;
    }

    return (
        <div>

            <Toaster
              position="top-center"
              reverseOrder={ false }
            />

            <header className="style2 stick">
                <div className="menu-box">
                    <div className="menu-box-inner">
                        <div className="logo">
                            <a href="/" title="Logo" itemprop="url">
                                <img className="header-logo" src="/images/logo2.png" alt="logo2.png" itemprop="image" />
                            </a>
                        </div>
                        <nav>
                            <div>

                                <ul className="srch-lnks">
                                    <li className={ "dlbtn "+( language == 'my' ? 'active' : '' ) }><a href="#" onClick={ () => changeLanguage( 'my' ) } >MY</a></li>
                                    <li className={ "dlbtn "+( language == 'cn' ? 'active' : '' ) }><a href="#" onClick={ () => changeLanguage( 'cn' ) } >CN</a></li>
                                    <li className={ "dlbtn "+( language == 'en' ? 'active' : '' ) }><a href="#" onClick={ () => changeLanguage( 'en' ) } >EN</a></li>
                                    { !isLogin && <li className="dlbtn"><a href="/auths/login">Login</a></li> }
                                    { !isLogin && <li className="dlbtn"><a href="/auths/register">Signup</a></li> }
                                    { isLogin && <li className="dlbtn"><a href="/auths/logout">Logout</a></li> }
                                    <li><a href="#" className="srch-btn" title="" itemprop="url"><i className="fa fa-search"></i></a></li>
                                </ul>
                                <ul className="dmenu">
                                    <li><a href="/" title="" itemprop="url">Home</a></li>
                                    <li><a href="/pages/cloud-mining" title="" itemprop="url">Cloud Mining</a></li>
                                    <li><a href="/pages/cloud-hosting" title="" itemprop="url">Cloud Hosting</a></li>
                                    <li><a href="/pages/bitmain-area" title="" itemprop="url">Bitmain Area</a></li>
                                    <li><a href="/pages/mining-logic" title="" itemprop="url">Mining Logic</a></li>
                                    <li><a href="/pages/about-us" title="" itemprop="url">About Us</a></li>
                                    <li><a href="/pages/suppliers" title="" itemprop="url">Suppliers</a></li>
                                    <li><a href="/pages/news" title="" itemprop="url">News</a></li>
                                    <li><a href="/pages/help-center" title="" itemprop="url">Help Center</a></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>

            <div className="hdr-srch-bx">
                <span className="srch-cls-btn"><i className="fa fa-close"></i></span>
                <form>
                    <input type="text" placeholder="Search your keywords..." />
                    <button type="submit"><i className="fa fa-search"></i></button>
                </form>
            </div>
            <div className="rspn-hdr">
                <div className="lg-mn">
                    <div className="logo">
                        <a href="/" title="Logo" itemprop="url">
                            <img className="header-logo" src="/images/logo2.png" alt="logo1.png" itemprop="image" />
                        </a>
                    </div>
                    {
                        !isLogin &&
                            <div className="bttns">
                                <a className="lbtn" href="/auths/login">Login</a>
                                <span> | </span>
                                <a className="lbtn" href="/auths/register">Signup</a>
                            </div>
                    }
                    {
                        isLogin &&
                            <div className="bttns">
                                <a className="lbtn" href="/auths/logout">Logout</a>
                            </div>
                    }
                    <span className="rspn-mnu-btn"><i className="fa fa-list-ul"></i></span>
                </div>
                <div className="rsnp-mnu">
                    <span className="rspn-mnu-cls"><i className="fa fa-times"></i></span>
                    <ul>
                        <li><a href="/" title="" itemprop="url">Home</a></li>
                        <li><a href="/pages/cloud-mining" title="" itemprop="url">Cloud Mining</a></li>
                        <li><a href="/pages/cloud-hosting" title="" itemprop="url">Cloud Hosting</a></li>
                        <li><a href="/pages/bitmain-area" title="" itemprop="url">Bitmain Area</a></li>
                        <li><a href="/pages/mining-logic" title="" itemprop="url">Mining Logic</a></li>
                        <li><a href="/pages/about-us" title="" itemprop="url">About Us</a></li>
                        <li><a href="/pages/suppliers" title="" itemprop="url">Suppliers</a></li>
                        <li><a href="/pages/news" title="" itemprop="url">News</a></li>
                        <li><a href="/pages/help-center" title="" itemprop="url">Help Center</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
