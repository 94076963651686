import React, { useState, useEffect, useRef } from 'react' ;

import Slider from "react-slick";

import { saveKey, getKey, requestData } from '../Main/Main' ;

import Header from '../Requires/Header' ;
import Footer from '../Requires/Footer' ;

const ProductDetails = props => {
    const [ language, setLanguage ] = useState( 'en' ) ;
    const [ data, setData ]         = useState( null ) ;
    const [ nav1, setNav1 ]         = useState( null ) ;
    const [ nav2, setNav2 ]         = useState( null ) ;

    const slider1                   = useRef( null ) ;
    const slider2                   = useRef( null ) ;

    useEffect( () => {
        async function callLanguage() {
            const lang   = await getKey( 'i18nextLng', 'en', false ) ;
            setLanguage( lang ) ;
        }
        callLanguage() ;
    }, '' ) ;

    useEffect( () => {
        async function callDetails() {
            const call   = await requestData( '/product/'+language+'/getProduct/'+props.match.params.id, { } ) ;
            if ( call.status == '1' ){
                setData( call.data ) ;
            }
        }
        callDetails() ;
    }, [] ) ;

    const settingsThumb = {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        slide: 'li',
        vertical: true,
        centerMode: true,
        centerPadding: '0',
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    vertical: true,
                    centerMode: true,
                    dots: false,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    vertical: true,
                    centerMode: true,
                    dots: false,
                    arrows: false
                }
            }
        ]
    } ;

    const settingsBig = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        slide: 'li',
        fade: false
    } ;

    return (
        <main>
            <Header />

            <content>
                <section>
                   <div className="spacing">
                       <div className="container">
                           <div className="prd-dtl-wrp">
                               <div className="row">
                                   {
                                        data != null &&
                                            <div className="col-md-6 col-sm-12 col-lg-6">
                                                {
                                                     ( data.productimage != null && data.productimage != undefined ) ?
                                                         ( data.productimage.length > 0 ) ?
                                                             <div className="prd-dtl-img-wrp">
                                                                 <Slider
                                                                   className="prd-dtl-img-nv"
                                                                   asNavFor={ nav2 }
                                                                   ref={ slider => { slider1.current = slider ; setNav1( slider1.current ) ; } }
                                                                   { ...settingsThumb } >
                                                                       { data.productimage.map( ( item, index ) => <li key={ index }><div className="prd-dtl-nv-thmb theme-bf-bg"><img src={ item } alt={ item } itemprop="image" /></div></li> )}
                                                                 </Slider>
                                                                 <Slider
                                                                   className="prd-dtl-img-big"
                                                                   asNavFor={ nav1 }
                                                                   ref={ slider => { slider2.current = slider ; setNav2( slider2.current ) ; } }
                                                                   { ...settingsBig } >
                                                                       { data.productimage.map( ( item, index ) => <li key={ index }><div className="prd-dtl-big-img"><img src={ item } alt={ item } itemprop="image" /></div></li> )}
                                                                 </Slider>
                                                             </div>
                                                         : ""
                                                     : ""
                                                }
                                            </div>
                                   }
                                   <div className="col-md-6 col-sm-12 col-lg-6">
                                       <div className="prd-dtl-desc">

                                           {
                                                data != null &&
                                                    <div>
                                                       <span className="prd-dtl-cat">{ data.isnew == 0 && <i>NEW</i> } { data.minermodels } 360days</span>
                                                       <h1 itemprop="headline">{ data.productname }</h1>
                                                       <span className="price">
                                                           <ins>${ data.price }</ins>
                                                           <del>${ data.price }</del>
                                                       </span>
                                                       <div className="ptables">
                                                            <table className="productdetails">
                                                               <tr>
                                                                  <td>Estimated Starting Time</td>
                                                                  <td>{ data.specifications.bootTime }</td>
                                                               </tr>
                                                               <tr>
                                                                  <td>Luogo</td>
                                                                  <td>{ data.specifications.luogo }</td>
                                                               </tr>
                                                               <tr>
                                                                  <td>Electricity Fee</td>
                                                                  <td>{ data.specifications.hashratefee }</td>
                                                               </tr>
                                                               <tr>
                                                                  <td>Hosting Fee</td>
                                                                  <td>{ data.specifications.hostingFee }</td>
                                                               </tr>
                                                               <tr>
                                                                  <td>Repairment Fee</td>
                                                                  <td>{ data.specifications.repairmentFee }</td>
                                                               </tr>
                                                            </table>
                                                       </div>
                                                    </div>
                                          }

                                          <div className="prd-dtl-btm" >
                                             <label>SELECT QUANTITY</label>
                                             <div className="prd-qty">
                                                 <input type="text" value="1" />
                                             </div>
                                          </div>
                                          
                                          {
                                              data != null &&
                                                  <div>
                                                      <div className="prd-dtl-btm">
                                                          <label>TOTAL</label>
                                                          <span className="totalprice">${ data.price }</span>
                                                      </div>
                                                      <div className="cobtn">
                                                           <a className="theme-bg theme-btn hrz" href="#" title="" itemprop="url">ADD TO CART</a>
                                                      </div>
                                                  </div>
                                          }

                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </section>

               {
                  data != null &&
                      <section>
                          <div className="spacing remove-gap">
                              <div className="container">
                                  <div className="prd-dtl-tbs">
                                      <ul className="nav nav-tabs">
                                          <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#prd-dtl-tb1">Static Analysis</a></li>
                                          <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#prd-dtl-tb2">Plan Specifications</a></li>
                                          <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#prd-dtl-tb3">FAQ</a></li>
                                          <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#prd-dtl-tb4">Disclaimer</a></li>
                                      </ul>
                                      <div className="tab-content">
                                          <div className="tab-pane fade show active" id="prd-dtl-tb1">
                                              <div className="tabcontent">
                                                  <h1>Analysis</h1>
                                                  <p>{ data.analysis }</p>
                                              </div>

                                          </div>
                                          <div className="tab-pane fade" id="prd-dtl-tb2">
                                              <div className="tabcontent">
                                                   <div className="ptables">
                                                       <table className="productdetails">
                                                          <tr>
                                                             <td>Luogo:</td>
                                                             <td>{ data.specifications.luogo }</td>
                                                          </tr>
                                                          <tr>
                                                             <td>Computing power：</td>
                                                             <td>{ data.specifications.power }</td>
                                                          </tr>
                                                          <tr>
                                                             <td>Power</td>
                                                             <td>{ data.specifications.power }</td>
                                                          </tr>
                                                          <tr>
                                                             <td>Energy ratio：</td>
                                                             <td>{ data.specifications.energyratio }</td>
                                                          </tr>
                                                       </table>
                                                       <table className="productdetails">
                                                          <tr>
                                                             <td>Boot Time</td>
                                                             <td>{ data.specifications.bootTime }</td>
                                                          </tr>
                                                          <tr>
                                                             <td>ELlectricity Fee</td>
                                                             <td>{ data.specifications.eletricityfee }</td>
                                                          </tr>
                                                          <tr>
                                                             <td>Hosting Fee:</td>
                                                             <td>{ data.specifications.hostingFee }</td>
                                                          </tr>
                                                          <tr>
                                                             <td>Repairment Fee</td>
                                                             <td className="bold">{ data.specifications.repairmentFee }</td>
                                                          </tr>
                                                       </table>
                                                   </div>
                                              </div>
                                          </div>
                                          <div className="tab-pane fade" id="prd-dtl-tb3">
                                              <div className="tabcontent">
                                                <div className="tabbody">
                                                  <div className="flex flex-column mb-5 mt-4 faq-section">
                                                    <div className="row">
                                                      <div className="col-md-12">
                                                        <div id="accordion">
                                                          <div className="card">
                                                            <div className="card-header" id="heading-1">
                                                              <h1 className="tabtitle">
                                                                <a role="button" data-toggle="collapse" href="#collapse-1" aria-expanded="true" aria-controls="collapse-1">
                                                                    What is FXCopier?
                                                                  </a>
                                                              </h1>
                                                            </div>
                                                            <div id="collapse-1" className="collapse show" data-parent="#accordion" aria-labelledby="heading-1">
                                                              <div className="tabbodyp">
                                                                FXCOPIER is the worlds most reliable remote trade copier. Its allow you to copy trades almost instantly between hundreds of MT4 accounts through the use of technology. Many of the industries leading money managers use FXCopier to easily manage multiple
                                                                client accounts in tandem.
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="card">
                                                            <div className="card-header" id="heading-2">
                                                              <h1 className="tabtitle">
                                                                <a className="collapsed" role="button" data-toggle="collapse" href="#collapse-2" aria-expanded="false" aria-controls="collapse-2">How to get this trade copier?</a>
                                                              </h1>
                                                            </div>
                                                            <div id="collapse-2" className="collapse" data-parent="#accordion" aria-labelledby="heading-2">
                                                              <div className="tabbody">
                                                                {
                                                                   /*
                                                                   <ul>
                                                                     <li>As specified in BitFuFu terms of use, once an order has been paid, it is unchangeable and non-refundable.</
                                                                     <li>BitFuFu will not be responsible for any results that arise from uncontrollable risks, including but not limited to natural disasters such as floods, volcanic eruptions, earthquakes, landslides, fires, storms, other weather events that can be assessed at the unforeseen or higher, government actions or directives, city-level grid power supply incidents as well as social actions or conflicts such as war, strikes, riots, etc.</li>
                                                                     <li>Hashrate fluctuation is inevitable. BitFuFu will not be responsible for fluctuations caused by unstable networks, network failures, miner performance, etc.</li>
                                                                    </ul>
                                                                   */
                                                                }
                                                              </div>
                                                            </div>
                                                          </div>

                                                        </div>
                                                      </div>

                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                          </div>

                                          <div className="tab-pane fade" id="prd-dtl-tb4">
                                              <div className="tabcontent">
                                                   <ul>
                                                      <li>As specified in BitFuFu terms of use, once an order has been paid, it is unchangeable and non-refundable.</li>
                                                      <li>BitFuFu will not be responsible for any results that arise from uncontrollable risks, including but not limited to natural disasters such as floods, volcanic eruptions, earthquakes, landslides, fires, storms, other weather events that can be assessed at the unforeseen or higher, government actions or directives, city-level grid power supply incidents as well as social actions or conflicts such as war, strikes, riots, etc.</li>
                                                      <li>Hashrate fluctuation is inevitable. BitFuFu will not be responsible for fluctuations caused by unstable networks, network failures, miner performance, etc.</li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                       </section>
               }

            </content>

            <Footer />
        </main>
    );
}

export default ProductDetails;
