import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom" ;

import './Css/webfonts.css' ;
import './Css/bootstrap.min.css' ;
import './Css/plugins.min.css' ;
import './Css/style.css' ;
import './Css/color.css' ;
import './Css/responsive.css' ;
import './Css/test.css' ;
import './Css/custom.css' ;
// import './Css/revolution/settings.css' ;
import './Css/revolution/navigation.css' ;

import Index          from './Dashboard/Index' ;
import CloudMining    from './Dashboard/CloudMining' ;
import CloudHosting   from './Dashboard/CloudHosting' ;
import BitmainArea    from './Dashboard/BitmainArea' ;
import MiningLogic    from './Dashboard/MiningLogic' ;
import AboutUs        from './Dashboard/AboutUs' ;
import Suppliers      from './Dashboard/Suppliers' ;
import News           from './Dashboard/News' ;
import HelpCenter     from './Dashboard/HelpCenter' ;

import ProductDetails from './Products/Details' ;

import Login          from './Auths/Login' ;
import Register       from './Auths/Register' ;
import Logout         from './Auths/Logout' ;

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={ Index } />
                <Route path="/pages/cloud-mining" component={ CloudMining } />
                <Route path="/pages/cloud-hosting" component={ CloudHosting } />
                <Route path="/pages/bitmain-area" component={ BitmainArea } />
                <Route path="/pages/mining-logic" component={ MiningLogic } />
                <Route path="/pages/about-us" component={ AboutUs } />
                <Route path="/pages/suppliers" component={ Suppliers } />
                <Route path="/pages/news" component={ News } />
                <Route path="/pages/help-center" component={ HelpCenter } />

                <Route path="/products/:id" component={ ProductDetails } />

                <Route path="/auths/login" component={ Login } />
                <Route path="/auths/register" component={ Register } />
                <Route path="/auths/logout" component={ Logout } />
            </Switch>
        </Router>
    );
}

export default App ;
