import React from 'react';
import { Redirect } from 'react-router-dom' ;

import { saveKey, showToast, requestData } from '../Main/Main' ;

import Header from '../Requires/Header' ;
import Footer from '../Requires/Footer' ;

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email    : '',
            password : ''
        }
    }

    render(){
        return (
            <main>
                <Header />

                <section>
                    <div class="spacing ylsh-layer opc7 top-spac70 bottom-spac70">
                        <div class="fixed-bg" style={{ backgroundImage: `url('images/about-us-2.png')` }} ></div>
                        <div class="container">
                            <div class="pur-sec-inr text-center">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-lg-12">
                                        <div class="sec-tl2 wht-clr text-center">
                                            <i>REGISTER</i>
                                            <h2 class="no-bf" itemprop="headline">Register Your Account</h2>
                                        </div>
                                        <div class="cnt-frm-wrp text-center">
                                            <form onSubmit={ this._submit }>
                                                <div class="row mrg20">
                                                    <div class="col-md-12 col-sm-12 col-lg-12">
                                                        <input type="text" name="email" onChange={ ( event ) => this.setState({ email : event.target.value }) } placeholder="Email" class="brd-rd3" />
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-lg-12">
                                                        <input type="password" name="password" onChange={ ( event ) => this.setState({ password : event.target.value }) } placeholder="Password" class="brd-rd3" />
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-lg-12">
                                                        <input type="text" name="referralcode" onChange={ ( event ) => this.setState({ referralcode : event.target.value }) } placeholder="Referral Code" class="brd-rd3" />
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-lg-12">
                                                        <button class="theme-btn hrz brd-rd3 theme-bf-bg" type="submit">REGISTER NOW</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />

            </main>
        );
    }

    _submit = async ( event ) => {
        event.preventDefault();

        const call   = await requestData( '/user/RegisterByEmail', {
            email        : this.state.email,
            password     : this.state.password,
            referralcode : this.state.referralcode
        } ) ;
        if ( call.status == '1' ){
            showToast( true, call.message ) ;

            window.location.href = '/auths/login' ;
        }else{
            showToast( false, call.message ) ;
        }
    }
}
