import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Header from '../Requires/Header' ;
import Footer from '../Requires/Footer' ;

function News() {
    return (
        <main>
            <Header />

            <section>
                <div class="spacing">
                    <div class="sec-tl2 text-center">
                            <i>LATEST NEWS</i>
                            <h2 class="no-bf" itemprop="headline">From BYTE</h2>
                        </div>
                    <div class="container">
                        <div class="amzng-prjcts text-center">
                            <ul class="fltr-lnks">
                                <li class="active"><a data-filter="*" href="#" itemprop="url">ALL NEWS</a></li>
                                <li><a data-filter=".fltr-itm1" href="#" itemprop="url">TYPE 1</a></li>
                                <li><a data-filter=".fltr-itm2" href="#" itemprop="url">TYPE 2</a></li>
                                <li><a data-filter=".fltr-itm3" href="#" itemprop="url">TYPE 3</a></li>
                            </ul>
                            <div class="fltr-dta remove-ext6 text-left">
                                <div class="row msonry">
                                    <div class="col-md-4 col-sm-6 col-lg-4 fltr-itm fltr-itm1">
                                        <div class="post-bx news-box">
                                            <div class="post-thumb hrz brd-rd3">
                                                <a href="blog-detail-left-sidebar.html" title="" itemprop="url"><img src="/images/resources/post-img1-2.jpg" alt="post-img1-2.jpg" itemprop="image" /></a>
                                            </div>
                                            <div class="post-info post-box">
                                                <h4 itemprop="headline"><a href="blog-detail-left-sidebar.html" title="" itemprop="url">News Title Goes Here</a></h4>
                                                <ul class="post-meta">
                                                    <li class="postdt"><i class="fa fa-clock-o"></i>February 3, 2015 11:00:56</li>
                                                </ul>
                                                <p class="newsdesc" itemprop="description">Integer posuere erat a ante venenatis dapibus posuere velit aliquetpraesent commodo cursus magna.</p>
                                                <a class="theme-btn hrz brd-rd3 theme-bf-bg" href="blog-detail-left-sidebar.html" title="" itemprop="url">READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6 col-lg-4 fltr-itm fltr-itm2">
                                        <div class="post-bx news-box">
                                            <div class="post-thumb hrz brd-rd3">
                                                <a href="blog-detail-left-sidebar.html" title="" itemprop="url"><img src="/images/resources/post-img1-2.jpg" alt="post-img1-2.jpg" itemprop="image" /></a>
                                            </div>
                                            <div class="post-info post-box">
                                                <h4 itemprop="headline"><a href="blog-detail-left-sidebar.html" title="" itemprop="url">News Title Goes Here</a></h4>
                                                <ul class="post-meta">
                                                    <li class="postdt"><i class="fa fa-clock-o"></i>February 3, 2015 11:00:56</li>
                                                </ul>
                                                <p class="newsdesc" itemprop="description">Integer posuere erat a ante venenatis dapibus posuere velit aliquetpraesent commodo cursus magna.</p>
                                                <a class="theme-btn hrz brd-rd3 theme-bf-bg" href="blog-detail-left-sidebar.html" title="" itemprop="url">READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6 col-lg-4 fltr-itm fltr-itm3">
                                        <div class="post-bx news-box">
                                            <div class="post-thumb hrz brd-rd3">
                                                <a href="blog-detail-left-sidebar.html" title="" itemprop="url"><img src="/images/resources/post-img1-2.jpg" alt="post-img1-2.jpg" itemprop="image" /></a>
                                            </div>
                                            <div class="post-info post-box">
                                                <h4 itemprop="headline"><a href="blog-detail-left-sidebar.html" title="" itemprop="url">News Title Goes Here</a></h4>
                                                <ul class="post-meta">
                                                    <li class="postdt"><i class="fa fa-clock-o"></i>February 3, 2015 11:00:56</li>
                                                </ul>
                                                <p class="newsdesc" itemprop="description">Integer posuere erat a ante venenatis dapibus posuere velit aliquetpraesent commodo cursus magna.</p>
                                                <a class="theme-btn hrz brd-rd3 theme-bf-bg" href="blog-detail-left-sidebar.html" title="" itemprop="url">READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6 col-lg-4 fltr-itm fltr-itm4">
                                        <div class="post-bx news-box">
                                            <div class="post-thumb hrz brd-rd3">
                                                <a href="blog-detail-left-sidebar.html" title="" itemprop="url"><img src="/images/resources/post-img1-2.jpg" alt="post-img1-2.jpg" itemprop="image" /></a>
                                            </div>
                                            <div class="post-info post-box">
                                                <h4 itemprop="headline"><a href="blog-detail-left-sidebar.html" title="" itemprop="url">News Title Goes Here</a></h4>
                                                <ul class="post-meta">
                                                    <li class="postdt"><i class="fa fa-clock-o"></i>February 3, 2015 11:00:56</li>
                                                </ul>
                                                <p class="newsdesc" itemprop="description">Integer posuere erat a ante venenatis dapibus posuere velit aliquetpraesent commodo cursus magna.</p>
                                                <a class="theme-btn hrz brd-rd3 theme-bf-bg" href="blog-detail-left-sidebar.html" title="" itemprop="url">READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6 col-lg-4 fltr-itm fltr-itm1 fltr-itm2">
                                        <div class="post-bx news-box">
                                            <div class="post-thumb hrz brd-rd3">
                                                <a href="blog-detail-left-sidebar.html" title="" itemprop="url"><img src="/images/resources/post-img1-2.jpg" alt="post-img1-2.jpg" itemprop="image" /></a>
                                            </div>
                                            <div class="post-info post-box">
                                                <h4 itemprop="headline"><a href="blog-detail-left-sidebar.html" title="" itemprop="url">News Title Goes Here</a></h4>
                                                <ul class="post-meta">
                                                    <li class="postdt"><i class="fa fa-clock-o"></i>February 3, 2015 11:00:56</li>
                                                </ul>
                                                <p class="newsdesc" itemprop="description">Integer posuere erat a ante venenatis dapibus posuere velit aliquetpraesent commodo cursus magna.</p>
                                                <a class="theme-btn hrz brd-rd3 theme-bf-bg" href="blog-detail-left-sidebar.html" title="" itemprop="url">READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6 col-lg-4 fltr-itm fltr-itm3 fltr-itm4">
                                        <div class="post-bx news-box">
                                            <div class="post-thumb hrz brd-rd3">
                                                <a href="blog-detail-left-sidebar.html" title="" itemprop="url"><img src="/images/resources/post-img1-2.jpg" alt="post-img1-2.jpg" itemprop="image" /></a>
                                            </div>
                                            <div class="post-info post-box">
                                                <h4 itemprop="headline"><a href="blog-detail-left-sidebar.html" title="" itemprop="url">News Title Goes Here</a></h4>
                                                <ul class="post-meta">
                                                    <li class="postdt"><i class="fa fa-clock-o"></i>February 3, 2015 11:00:56</li>
                                                </ul>
                                                <p class="newsdesc" itemprop="description">Integer posuere erat a ante venenatis dapibus posuere velit aliquetpraesent commodo cursus magna.</p>
                                                <a class="theme-btn hrz brd-rd3 theme-bf-bg" href="blog-detail-left-sidebar.html" title="" itemprop="url">READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </main>
    );
}


export default News;
