import React from 'react' ;
import axios from "axios" ;

import toast, { Toaster } from 'react-hot-toast' ;

export const showToast = async ( result, message ) => {
    if ( result ){
        toast.success( message ) ;
    }else{
        toast.error( message ) ;
    }
}

export const removeAllKey = async ( ) => {
    try {
        await localStorage.clear() ;
    } catch (error) {
        showToast( false, "Error saving data" + error ) ;
    }
}

export const removeKey = (storage) => {
    try {
        localStorage.removeItem(storage) ;
    } catch (error) {
        showToast( false, "Error saving data" + error ) ;
    }
}

export const saveKey = ( storage, value, json = true ) => {
    try {
        localStorage.setItem( storage, ( json ? JSON.stringify(value) : value ) ) ;
    } catch (error) {
        showToast( false, "Error saving data" + error ) ;
    }
}

export const getKey = async ( storage, type = null, json = true ) => {
    try {
        const data = await localStorage.getItem(storage) ;
        if ( data !== null ) {
            if ( json ){
                return JSON.parse(data) ;
            }else{
                return data ;
            }
        }
    } catch (error) {
        showToast( false, "Error get data" + error ) ;
    }
    return type ;
}

export const requestData = async ( api, parameter ) => {

    let result = {
        'status'  : '0',
        'message' : 'Failed',
        'data'    : {}
    } ;

    try {
        const auth        = await getKey( 'Auth', null ) ;
        const accesstoken = ( auth != null ? auth.accesstoken : '' ) ;

        var formdata      = new FormData() ;
        if ( Object.keys(parameter).length > 0 ){
            Object.keys(parameter).map( key => {
                formdata.append( key, parameter[key] ) ;
            })
        }

        const url      = process.env.REACT_APP_APIURL + api ;
        const response = await axios.post( url, formdata, {
            headers : {
              "Authorization" : process.env.REACT_APP_APIKEY,
              "AccessToken" : accesstoken,
              "Content-Type" : "multipart/form-data"
            }
        }) ;
        const request = response.data ;

        let status    = '0' ;
        let data      = {} ;
        if ( request.result ){
            status    = '1' ;
            data      = request.data ;
        }else{
            status    = '2' ;
        }

        console.log(url);
        console.log(request);

        result = {
            'status'  : status,
            'message' : request.msg,
            'data'    : data
        }

     } catch (error) {
          result.status  = '5' ;
          result.message = error ;
          showToast( false, 'No Internet Access' ) ;
     }

     return result ;
}
