function Footer() {
    return (
        <div>
            <footer>
                <div className="spacing drk-bg top-spac80 bottom-spac80">
                    <div className="container">
                        <div className="ftr-wrp remove-ext5">
                            <div className="row">
                                <div className="col-md-6 col-sm-12 col-lg-6">
                                    <div className="wdgt abt-wdgt">
                                        <div className="logo"><a href="/" title="" itemprop="url"><img src="/images/logo2.png" alt="logo2.png" itemprop="image" /></a></div>
                                        <p className="bslogan" itemprop="description">Byte Strategy, A Revolutionise Mining Platform</p>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-12 col-lg-2 dfr">
                                    <div className="wdgt">
                                        <h4 itemprop="headline" className="wdgt-tl">Product</h4>
                                        <div className="rcnt-itm">
                                            <h4 itemprop="headline"><a href="/cloud-mining" title="" itemprop="url">Cloud Mining</a></h4>
                                        </div>
                                        <div className="rcnt-itm">
                                            <h4 itemprop="headline"><a href="/cloud-hosting" title="" itemprop="url">Cloud Hosting</a></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-12 col-lg-2 dfr">
                                    <div className="wdgt">
                                        <h4 itemprop="headline" className="wdgt-tl">Cooperation</h4>
                                        <div className="rcnt-itm">
                                            <h4 itemprop="headline"><a href="become-agents.html" title="" itemprop="url">Become Agents</a></h4>
                                        </div>
                                        <div className="rcnt-itm">
                                            <h4 itemprop="headline"><a href="become-suppliers.html" title="" itemprop="url">Become Suppliers</a></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-12 col-lg-2 dfr">
                                    <div className="wdgt">
                                        <h4 itemprop="headline" className="wdgt-tl">Support</h4>
                                        <div className="rcnt-itm">
                                            <h4 itemprop="headline"><a href="help-center.html" title="" itemprop="url">Help Center</a></h4>
                                        </div>
                                        <div className="rcnt-itm">
                                            <h4 itemprop="headline"><a href="privacy-policy.html" title="" itemprop="url">Privacy Policy</a></h4>
                                        </div>
                                        <div className="rcnt-itm">
                                            <h4 itemprop="headline"><a href="term-of-use.html" title="" itemprop="url">Term Of Use</a></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="btm-br text-center">
                <div className="container">
                    <p className="newsdesc" itemprop="description">&copy; All Rights Reserved <a href="/" title="byte" itemprop="url" target="_blank">Byte Holdings</a></p>
                </div>
            </div>
        </div>
    );
}


export default Footer;
