import React, { useState, useEffect } from 'react' ;

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { saveKey, getKey, requestData, showToast } from '../Main/Main' ;

import Header from '../Requires/Header' ;
import Footer from '../Requires/Footer' ;

function Index() {
    const [ language, setLanguage ] = useState( 'en' ) ;
    const [ carousel, setCarousel ] = useState( false ) ;

    const [ btc, setBtc ]   = useState( null ) ;
    const [ eth, setEth ]   = useState( null ) ;
    const [ bch, setBch ]   = useState( null ) ;
    const [ dash, setDash ] = useState( null ) ;

    useEffect( () => {
        async function callLanguage() {
            const lang   = await getKey( 'i18nextLng', 'en', false ) ;
            setLanguage( lang ) ;
        }
        callLanguage() ;
    }, '' ) ;

    useEffect( () => {
        async function callProduct() {
            const call   = await requestData( '/product/'+language+'/getProduct', { } ) ;
            if ( call.status == '1' ){
                call.data.map( (value, index) => {
                    switch ( value.outputToken ){
                        case 'BTC' :
                            setBtc( value ) ;
                        break ;
                        case 'ETH' :
                            setEth( value ) ;
                        break ;
                        case 'BCH' :
                            setBch( value ) ;
                        break ;
                        case 'DASH' :
                            setDash( value ) ;
                        break ;
                    }
                })
                setCarousel( true ) ;
            }
        }
        callProduct() ;
    }, [] ) ;

    return (
        <main>
           <Header />

           <section>
                <div className="theme-bg spacing bit-fea-wrp overlap85">
                    <div className="fixed-bg2 wtd-drk-bg" style={{ backgroundImage: `url('images/banner/2.png')` }}></div>
                    <div className="container">
                        <div className="bit-fea-inr">
                            <div className="row">
                                <div className="col-md-6 col-sm-12 col-lg-6"></div>
                                <div className="col-md-6 col-sm-12 col-lg-6">
                                    <div className="bit-fea">
                                        <h1 itemprop="headline"><span className="theme-clr">Byte Strategy,</span> A Revolutionise Mining Platform</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </section>


           <section id="ads">
                <div className="spacing top-spac30 bottom-spac30">
                    <div className="container">
                        <div className="testi-spnsr">
                            <div className="row">
                                <div className="sec-tl2 text-center">
                                    <i>Connect With Us Now</i>
                                    <h2 className="no-bf" itemprop="headline">Begin your mining Journey</h2>
                                </div>
                                <div className="col-md-12 col-sm-12 col-lg-12">
                                    <div className="spnsr-car text-center owl-carousel">
                                        <div className="spnsr-bx">
                                            <a href="#" title="" itemprop="url"><img src="images/ads/a1.png" alt="ads" itemprop="image" /></a>
                                        </div>
                                        <div className="spnsr-bx">
                                            <a href="#" title="" itemprop="url"><img src="images/ads/a2.png" alt="ads" itemprop="image" /></a>
                                        </div>
                                        <div className="spnsr-bx">
                                            <a href="#" title="" itemprop="url"><img src="images/ads/a3.png" alt="ads" itemprop="image" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </section>

           <section>
                <div className="bottom-spac190">
                    <div className="container-fullwidth">
                        <div class="tabs">
                            <nav class="tab-nav">
                                <ul class="firsttab">
                                    <li className="active"><span data-href="#tab-1">BTC</span></li>
                                    <li><span data-href="#tab-2">ETH</span></li>
                                    <li><span data-href="#tab-3">BCH</span></li>
                                    <li><span data-href="#tab-4">DASH</span></li>
                                </ul>
                            </nav>
                            <div class="container">
                                <Coins id="tab-1" list={ btc } status="active" />
                                <Coins id="tab-2" list={ eth } status="" />
                                <Coins id="tab-3" list={ bch } status="" />
                                <Coins id="tab-4" list={ dash } status="" />
                            </div>
                        </div>
                    </div>
                </div>
           </section>

           <section>
                <div className="spacing black-layer opc7 bottom-spac100">
                    <div className="fixed-bg" style={{ backgroundImage: `url('images/about-us-2.png')` }} ></div>
                    <div id="prtcl2" className="particle-bx"></div>
                    <div className="container">
                        <div className="pur-sec text-center">
                            <div className="pur-sec-inr">
                                <span className="theme-clr">A revolutionary mining platform</span>
                                <h2 itemprop="headline">Flexibility selection in connecting into world of bitcoin mining ,openness in the mining output，and secure in acquiring and contract for miners </h2>
                            </div>
                        </div>
                    </div>
                </div>
           </section>

           <section>
               <div className="spacing no-spacing">
                   <div className="container">
                       <div className="fn-fct-wrp text-center overlap125">
                           <div className="remove-ext3">
                               <div className="row">
                                   <div className="col-md-3 col-sm-6 col-lg-3">
                                       <div className="fn-fct-bx">
                                           <i className="iconc"><img src="/images/iconr1.png" alt="icon" itemprop="image" /></i>
                                           <h4 itemprop="headline">No Downtime resources</h4>
                                           <i>A team of expert are standby 24/7/365, make sure there is no downtimes on all your services</i>
                                       </div>
                                   </div>
                                   <div className="col-md-3 col-sm-6 col-lg-3">
                                       <div className="fn-fct-bx">
                                           <i className="iconc"><img src="/images/iconr2.png" alt="icon" itemprop="image" /></i>
                                           <h4 itemprop="headline">Monitor Mining</h4>
                                           <i>Hash rate status and earnings are monitor through your own mining pool account</i>
                                       </div>
                                   </div>
                                   <div className="col-md-3 col-sm-6 col-lg-3">
                                       <div className="fn-fct-bx">
                                           <i className="iconc"><img src="/images/iconr3.png" alt="icon" itemprop="image" /></i>
                                           <h4 itemprop="headline">Consistent Supplies</h4>
                                           <i>Hash rate resources are monitored closely, and we can handle bulk purchase of hash rate from the market anytime</i>
                                       </div>
                                   </div>
                                   <div className="col-md-3 col-sm-6 col-lg-3">
                                       <div className="fn-fct-bx">
                                           <i className="iconc"><img src="/images/iconr4.png" alt="icon" itemprop="image" /></i>
                                           <h4 itemprop="headline">Income growth & trade anytime</h4>
                                           <i>Sustain revenue growth by increasing your hash rate acquisition, and trade your output at any time wanted, no locking on your asset</i>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </section>

           <section>
               <div className="spacing gray-bg">
                   <div className="container">
                       <div className="srv-wrp">
                           <div className="row">
                               <div className="col-md-4 col-sm-12 col-lg-4">
                                   <div className="sec-tl1">
                                       <i>OUR MEMBERS</i>
                                       <h2 itemprop="headline">What Our Member Says</h2>
                                       <p className="newsdesc" itemprop="description">Donec sed odio dui. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donecullamcorper nulla non metus auctor fringilla.</p>
                                       <a href="#" title="" itemprop="url">View More</a>
                                   </div>
                               </div>
                               <div className="col-md-8 col-sm-12 col-lg-8">
                                   <div className="srv-car owl-carousel dots-center">
                                       <div className="srv-bx text-center">
                                           <i className="brd-rd50"><img src="/images/resources/srv-icn1-1.png" alt="srv-icn1-1.png" itemprop="image" /></i>
                                           <h4 itemprop="headline"><a href="#" title="" itemprop="url">Happy Member</a></h4>
                                           <p className="newsdesc" itemprop="description">Artbees is humbled and proud to be showcased in many criti allyacclaimed blogs and online magazines.</p>
                                           <a href="#" title="" itemprop="url">Read More</a>
                                       </div>
                                       <div className="srv-bx text-center">
                                           <i className="brd-rd50"><img src="/images/resources/srv-icn1-2.png" alt="srv-icn1-2.png" itemprop="image" /></i>
                                           <h4 itemprop="headline"><a href="#" title="" itemprop="url">Happy Member</a></h4>
                                           <p className="newsdesc" itemprop="description">Artbees is humbled and proud to be showcased in many criti allyacclaimed blogs and online magazines.</p>
                                           <a href="#" title="" itemprop="url">Read More</a>
                                       </div>
                                       <div className="srv-bx text-center">
                                           <i className="brd-rd50"><img src="/images/resources/srv-icn1-3.png" alt="srv-icn1-3.png" itemprop="image" /></i>
                                           <h4 itemprop="headline"><a href="#" title="" itemprop="url">Happy Member</a></h4>
                                           <p className="newsdesc" itemprop="description">Artbees is humbled and proud to be showcased in many criti allyacclaimed blogs and online magazines.</p>
                                           <a href="#" title="" itemprop="url">Read More</a>
                                       </div>
                                   </div>
                               </div>

                           </div>
                       </div>
                   </div>
               </div>
           </section>

           <section>
               <div className="spacing top-spac70">
                   <div className="container">
                       <div className="sec-tl2 text-center">
                           <i>LATEST NEWS</i>
                           <h2 className="no-bf" itemprop="headline">From BYTE</h2>
                       </div>
                       <div className="remove-ext3">
                           <div className="row">
                               <div className="col-md-4 col-sm-12 col-lg-4">
                                   <div className="post-bx news-box">
                                       <div className="post-thumb hrz brd-rd3">
                                           <a href="news.html" title="" itemprop="url"><img src="/images/resources/post-img1-1.jpg" alt="post-img1-1.jpg" itemprop="image" /></a>
                                       </div>
                                       <div className="post-info">
                                           <h4 itemprop="headline"><a href="news.html" title="" itemprop="url">Long Long Long Very Long News Title Goes Here</a></h4>
                                           <ul className="post-meta">
                                               <li className="postdt"><i className="fa fa-clock-o"></i>February 3, 2015 11:00:56</li>
                                           </ul>
                                           <p className="newsdesc" itemprop="description">Integer posuere erat a ante venenatis dapibus posuere velit aliquetpraesent commodo cursus magna venenatis dapibus posuere velit aliquetpraesent commodo cursus magna.</p>
                                           <a className="theme-btn hrz brd-rd3 theme-bf-bg" href="news.html" title="" itemprop="url">READ MORE</a>
                                       </div>
                                   </div>
                               </div>
                               <div className="col-md-4 col-sm-12 col-lg-4">
                                   <div className="post-bx news-box">
                                       <div className="post-thumb hrz brd-rd3">
                                           <a href="news.html" title="" itemprop="url"><img src="/images/resources/post-img1-2.jpg" alt="post-img1-2.jpg" itemprop="image" /></a>
                                       </div>
                                       <div className="post-info">
                                           <h4 itemprop="headline"><a href="news.html" title="" itemprop="url">News Title Goes Here</a></h4>
                                           <ul className="post-meta">
                                               <li className="postdt"><i className="fa fa-clock-o"></i>February 3, 2015 11:00:56</li>
                                           </ul>
                                           <p className="newsdesc" itemprop="description">Integer posuere erat a ante venenatis dapibus posuere velit aliquetpraesent commodo cursus magna.</p>
                                           <a className="theme-btn hrz brd-rd3 theme-bf-bg" href="news.html" title="" itemprop="url">READ MORE</a>
                                       </div>
                                   </div>
                               </div>
                               <div className="col-md-4 col-sm-12 col-lg-4">
                                   <div className="post-bx news-box">
                                       <div className="post-thumb hrz brd-rd3">
                                           <a href="news.html" title="" itemprop="url"><img src="/images/resources/post-img1-3.jpg" alt="post-img1-3.jpg" itemprop="image" /></a>
                                       </div>
                                       <div className="post-info">
                                           <h4 itemprop="headline"><a href="news.html" title="" itemprop="url">News Title Goes Here</a></h4>
                                           <ul className="post-meta">
                                               <li className="postdt"><i className="fa fa-clock-o"></i>February 3, 2015 11:00:56</li>
                                           </ul>
                                           <p className="newsdesc" itemprop="description">Integer posuere erat a ante venenatis dapibus posuere velit aliquetpraesent commodo cursus magna.</p>
                                           <a className="theme-btn hrz brd-rd3 theme-bf-bg" href="news.html" title="" itemprop="url">READ MORE</a>
                                       </div>
                                   </div>
                               </div>
                           </div>
                           <div className="fltr-btm text-center">
                               <h3 itemprop="headline">See more news? Let's check now</h3>
                               <a className="theme-btn hrz" href="news.html" title="" itemprop="url">View All News</a>
                           </div>
                       </div>
                   </div>
               </div>
           </section>

           <section>
               <div className="spacing gray-bg top-spac100 bottom-spac100">
                   <div className="container">
                       <div className="partners">
                           <div className="sec-tl2 text-center">
                               <i>Our Partners</i>
                               <h2 className="no-bf" itemprop="headline">Meet Our Partners</h2>
                           </div>
                           <div className="row">
                               <div className="col-md-2 col-sm-6 col-lg-2">
                                   <img className="partners" src="/images/partners/1.png" alt="partner" itemprop="image" />
                               </div>
                               <div className="col-md-2 col-sm-6 col-lg-2">
                                   <img className="partners" src="/images/partners/1.png" alt="partner" itemprop="image" />
                               </div>
                               <div className="col-md-2 col-sm-6 col-lg-2">
                                   <img className="partners" src="/images/partners/1.png" alt="partner" itemprop="image" />
                               </div>
                               <div className="col-md-2 col-sm-6 col-lg-2">
                                   <img className="partners" src="/images/partners/1.png" alt="partner" itemprop="image" />
                               </div>
                               <div className="col-md-2 col-sm-6 col-lg-2">
                                   <img className="partners" src="/images/partners/1.png" alt="partner" itemprop="image" />
                               </div>
                               <div className="col-md-2 col-sm-6 col-lg-2">
                                   <img className="partners" src="/images/partners/1.png" alt="partner" itemprop="image" />
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </section>

           <section>
               <div className="spacing">
                   <div className="fixed-bg" style={{ backgroundImage: `url('images/contact-us.png')` }} ></div>
                   <div className="container">
                       <div className="get-tuch-wrp">
                           <div className="row">
                               <div className="col-md-7 col-sm-12 col-lg-7">
                                   <div className="sec-tl2 wht-clr text-center">
                                       <i>CONTACT US</i>
                                       <h2 className="no-bf" itemprop="headline">Get In Touch</h2>
                                   </div>
                                   <div className="cnt-frm-wrp text-center">
                                       <form>
                                           <div className="row mrg20">
                                               <div className="col-md-6 col-sm-6 col-lg-6">
                                                   <input type="text" placeholder="Name" className="brd-rd3" />
                                               </div>
                                               <div className="col-md-6 col-sm-6 col-lg-6">
                                                   <input type="email" placeholder="Email" className="brd-rd3" />
                                               </div>
                                               <div className="col-md-12 col-sm-12 col-lg-12">
                                                   <textarea placeholder="Message" className="brd-rd3"></textarea>
                                               </div>
                                               <div className="col-md-12 col-sm-12 col-lg-12">
                                                   <button className="theme-btn hrz brd-rd3 theme-bf-bg" type="submit">SUBMIT NOW</button>
                                               </div>
                                           </div>
                                       </form>
                                   </div>
                               </div>
                               <div className="col-md-5 col-sm-12 col-lg-5">
                                   <div className="cnt-inf-wrp cnt-inf-car owl-carousel bg-white">
                                       <div className="cnt-inf-itm">
                                           <h4 itemprop="headline">Branch office #1</h4>
                                           <ul className="cnt-inf-lst">
                                               <li><i className="fa fa-map-marker"></i> Lorem Ipsum Dolor Lorem dolor ipsum</li>
                                               <li><i className="fa fa-clock-o"></i> Monday to Friday: 9am to 8pm</li>
                                               <li><i className="fa fa-envelope-o"></i> <a href="#" title="" itemprop="url">byte@email.com</a></li>
                                               <li><i className="fa fa-mobile"></i> 0000-000-000</li>
                                           </ul>
                                       </div>
                                       <div className="cnt-inf-itm">
                                           <h4 itemprop="headline">Branch office #2</h4>
                                           <ul className="cnt-inf-lst">
                                               <li><i className="fa fa-map-marker"></i> Lorem Ipsum Dolor Lorem dolor ipsum</li>
                                               <li><i className="fa fa-clock-o"></i> Monday to Friday: 9am to 8pm</li>
                                               <li><i className="fa fa-envelope-o"></i> <a href="#" title="" itemprop="url">byte@email.com</a></li>
                                               <li><i className="fa fa-mobile"></i> 0000-000-000</li>
                                           </ul>
                                       </div>
                                       <div className="cnt-inf-itm">
                                           <h4 itemprop="headline">Branch office #3</h4>
                                           <ul className="cnt-inf-lst">
                                               <li><i className="fa fa-map-marker"></i> Lorem Ipsum Dolor Lorem dolor ipsum</li>
                                               <li><i className="fa fa-clock-o"></i> Monday to Friday: 9am to 8pm</li>
                                               <li><i className="fa fa-envelope-o"></i> <a href="#" title="" itemprop="url">byte@email.com</a></li>
                                               <li><i className="fa fa-mobile"></i> 0000-000-000</li>
                                           </ul>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </section>

           <Footer />

        </main>
    )
}

const Coins = props => {
    const list = ( props.list != null ? props.list : [] ) ;
    return (
        <div class={ 'tab ' + props.status } id={ props.id } >
            <div class="tabs">
                <nav class="tab-nav">
                    <ul class="secondtab">
                        <li class="active"><span data-href={ "#sub-"+props.id+"-1" } >Recommend</span></li>
                        <li><span data-href={ "#sub-"+props.id+"-2" } >Cloud Mining</span></li>
                        <li><span data-href={ "#sub-"+props.id+"-3" } >Cloud Service</span></li>
                        <li><span data-href={ "#sub-"+props.id+"-4" } >Cloud Hosting</span></li>
                    </ul>
                </nav>
                <Details id={ "sub-"+props.id+"-1" } type="recommendList" list={ list.recommendList } status="active" />
                <Details id={ "sub-"+props.id+"-2" } type="cloudMiningList" list={ list.cloudMiningList } status="" />
                <Details id={ "sub-"+props.id+"-3" } type="cloudServiceList" list={ list.cloudServiceList } status="" />
                <Details id={ "sub-"+props.id+"-4" } type="cloudHosting" list={ list.cloudHosting } status="" />
            </div>
        </div>
    )
}

const Details   = props => {
    var title   = '' ;
    var content = '' ;
    switch ( props.type ){
        case 'recommendList' :
            title   = 'Cloud' ;
            content = 'Recommend for you' ;
        break ;
        case 'cloudMiningList' :
            title   = 'Mining To Owned' ;
            content = 'Rent mining machines to get more computing power at low cost' ;
        break ;
        case 'cloudServiceList' :
            title   = 'Cloud Service' ;
            content = 'Cloud service machine property rights gaining output while enjoying asset appreciation' ;
        break ;
        case 'cloudHosting' :
            title   = 'Cloud hosting' ;
            content = 'Owning mining machine property rights gaining output while enjoying asset appreciation' ;
        break ;
    }

    return (
        <div class={ 'tab ' + props.status } id={ props.id } >
            <div class="srv-wrp">
                <div class="row mb80">
                    <div class="col-md-2 col-lg-2 col-sm-12">
                        <div class="sec-tl1">
                            <h2 itemprop="headline">{ title }</h2>
                            <p class="desc" itemprop="description">{ content }</p>
                        </div>
                    </div>
                    <div class="col-md-10 col-lg-10 col-sm-12">
                        {
                            ( props.list != null && props.list != undefined ) ?
                                ( props.list.length > 0 ) ?
                                    <div className="srv-car dots-center">
                                        <OwlCarousel className='owl-theme' loop items={ 2 } margin={ 10 } nav={ false }>
                                            {
                                                props.list.map( (item, index) =>
                                                    <div class="fea-bx fborderbox" key={ index }>
                                                         <div class="fea-bx-inf">
                                                              <div class="row">
                                                                   <div class="col-md-4 col-lg-4 col-sm-12">
                                                                      <img class="ppic" src={ item.productimageurl } alt="" itemprop="image" />
                                                                   </div>
                                                                   <div class="col-md-8 col-lg-8 col-sm-12">
                                                                      <h4 class="sub1">{ item.minermodels }</h4>
                                                                      <h2 class="ptitle" itemprop="headline">{ item.productname }</h2>
                                                                      <h4 class="sub2">Luogo: { item.suppliername }</h4>
                                                                      <table class="productdetails">
                                                                         <tbody><tr>
                                                                            <td>Luogo:</td>
                                                                            <td>{ item.suppliername }</td>
                                                                         </tr>
                                                                         <tr>
                                                                            <td>Computing power：</td>
                                                                            <td>{ item.power }</td>
                                                                         </tr>
                                                                         <tr>
                                                                            <td>Energy ratio：</td>
                                                                            <td>{ item.energyratio }</td>
                                                                         </tr>
                                                                      </tbody></table>
                                                                      <a href={ '/products/'+item.productid } class="theme-btn hrz brd-rd3 theme-bf-bg" title="" itemprop="url">Check Details</a>
                                                                  </div>
                                                              </div>
                                                         </div>
                                                    </div>
                                                )
                                            }
                                        </OwlCarousel>
                                    </div>
                               : ""
                            : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index ;
