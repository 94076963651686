import React from 'react';

import { removeAllKey } from '../Main/Main' ;

export default class Logout extends React.Component {
    constructor(props) {
        super(props);
    }

    render(){
        return null ;
    }

    componentDidMount = () => {
        removeAllKey() ;
        window.location.href = '/' ;
    }
}
