import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Header from '../Requires/Header' ;
import Footer from '../Requires/Footer' ;

function HelpCenter() {
    return (
        <main>
            <Header />

            

            <Footer />

        </main>
    );
}


export default HelpCenter;
